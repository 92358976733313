/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement } from 'react'
import { ProposalActionKey, ProposalActionTaskSet } from 'src/pages/shared'

export interface API<T> {
  data: T
  code: 'SUCCESS'
  message: 'Success'
}

export enum STYLE_IMAGE_TYPE {
  WIDE = 'WIDE',
  NARROW = 'NARROW',
}

export enum SESSION_STATE {
  STYLE_CALCULATED = 'STYLE_CALCULATED',
  SWIPING = 'SWIPING',
  SUBMITTED = 'SUBMITTED',
  CONTACT_INFO_ANSWERED = 'CONTACT_INFO_ANSWERED',
  BUDGET_ANSWERED = 'BUDGET_ANSWERED',
  COLOR_PALETTE_ANSWERED = 'COLOR_PALETTE_ANSWERED',
  CUSTOM_PALETTE_ANSWERED = 'CUSTOM_PALETTE_ANSWERED',
  INSPIRATION_PHOTO_ANSWERED = 'INSPIRATION_PHOTO_ANSWERED',
  REVIEWED_SAMPLE_FLOWER = 'REVIEWED_SAMPLE_FLOWER',
}

export type QuizExperience = 'CANNY_VALLEY' | 'LEGACY'

export interface IStyleImages {
  imageUrl: string
  type: STYLE_IMAGE_TYPE
}

export interface CalculateResponse {
  styleText: string
  styleImages: IStyleImages[]
}

export interface MoodboardImage {
  imageUrl: string
  position: number
}

export interface ColorPreference {
  [key: string]: number
}

export interface ColorPalette {
  id: number
  name: string
  colors: { hex: string; width: number }[]
  colorList: string[]
  images: {
    desktop: { position: number; url: string; img?: ReactElement }[]
    mobile: { position: number; url: string; img?: ReactElement }[]
  }
  customerName: string
  location: string
  moodboardImages: MoodboardImage[]
  description: string
  orbConfig: {
    orb_colors: string[]
    imageUrls: string[]
    layout: string
  }
  overviewCarousels: OverviewCarousel[]
}

export interface Inspiration {
  imageUrl: string
  label: string
  id?: number
  eventId?: number
  styleImagePreferenceOptionId?: number
}

export type Stem = {
  colorPaletteId: number
  stemId: number
  stem: {
    id: number
    name: string
    typeName: string
    description: string
    imageUrl: string
  }
}

export enum BudgetFocus {
  'EQUAL' = 'EQUAL',
  'CEREMONY' = 'CEREMONY',
  'RECEPTION' = 'RECEPTION',
}
export enum StemPreferenceValue {
  'LIKE' = 'LIKE',
  'LOVE' = 'LOVE',
  'EXCLUDE' = 'EXCLUDE',
}

export type BudgetContent = {
  amount: number
  images: string[]
  focus: BudgetFocus
}

export type CustomerStemPreference = {
  id?: number
  customerId?: number
  stemId: number
  value?: StemPreferenceValue | null
  stem?: {
    id: number
    name: string
    typeName: string
    description: string
    imageUrl: string
  }
}

export interface CustomerInfo {
  id: number
  name: string
  displayName: string
  emailAddress: string
  phoneNumber: string
  pinterestBoard: string
  contactId: number
  attributionSource: string
  firstName: string
  lastName: string
  spouseName: string
  partnerName: string
  colorPreference?: any
}

export interface CommentOwner {
  id: number
  creditCardFeeOverride: any
  emailAddress: string
  insertedAt: string
  isAdmin: boolean
  knockUserId: string
  magicUserId: string
  poppyCustomerId: number
  rootPortalUserId: number
  stripeCustomerId: string
  stytchUserId: string
  updatedAt: string
  poppyCustomer?: CustomerInfo
  isPlanner?: boolean
}

export interface ProposalUnit {
  id: number
  unitType: string
  itemType: string
  quantity: number
  sequenceIndex: number
  commentCount: number
  unitName: string
  unitTypeDisplay: string
  unreadCommentCount: number
  notInterested: boolean
  detailLoaded?: boolean
  isHidden?: boolean
  newItemAdded: boolean
  lastComment?: CommentItem
}

export interface CommentItem {
  id: number
  channelId?: number
  userFrom: CommentOwner
  userFromId: number
  userTo?: any
  content: string
  proposalUnit?: ProposalUnit
  proposalUnitId?: number
  insertedAt: string
  updatedAt?: string
  owned?: boolean
  userReadIds: number[]
  tempId?: string
  attachment?: File
  attachmentUrl?: string
  justRead?: boolean
}

export interface OverviewCarousel {
  unitType: string
  imageUrl: string
  unitId: number
  pathToUnitDetail?: string
}

export interface ProposalActionItem {
  actionKey: ProposalActionKey
  id: number
  isChecked: boolean
  proposalId: number
  taskSet: ProposalActionTaskSet
}

export interface OrbConfig {
  imageUrls: string[]
  layout: string
  orb_colors: string[]
}

export interface IQuizSessionsResponse {
  firstName: string
  sessionState: SESSION_STATE
  quizExperience: QuizExperience
  experiment: string[]
  customer: {
    id: number
    name: string
    displayName: string
    firstName: string
    lastName: string
    partnerName: string
    emailAddress: string
    phoneNumber: string
    pinterestBoard: string
    contactId: number
    inspirationImages: string[]
    attributionSource: string
    plannerOwner: number
    spouseName: string
    colorPreference: any
  }
  event: {
    id: number
    date: Date
    city: string
    state: string
    venueStreetAddress: string
    venueName: string
    primaryCustomerAttire: string
    secondaryCustomerAttire: string
    guestCount: number
    guestCountUnknown: boolean
    additionalBouquetsNeeded: number
    additionalBoutsNeeded: number
    additionalItemsUnknown: boolean
    weddingAttire: string
    budget: number
    budgetFocus: BudgetFocus
    dayOfPointOfContactName: string
    dayOfPointOfContactPhoneNumber: string
    dealId: number
    dealStage: string
    dealStatus: string
    experienceType: string
    otherInformation: string
    otherBookedVendors: string[]
    covidImpact: boolean
    planningStage: string
    floralFocus: string
    marketId: number
    primaryCtaType: string
    serviceLevel: string
    designerId: number
    totalPrepTime: number
    totalDesignTime: number
    totalInstallTime: number
    totalLoadingTime: number
    totalTime: number
    claimedAt: Date
    owner: {
      id: number
      calendlyLink: string
      firstName: string
      lastName: string
      avatar: string
      description: string
      location: string
    }
    quantityNeed: any
    referralSource: string
  }
  locations: {
    id: number
    name: string
    streetAddress: string
    city: string
    state: string
    type: string
    zipCode: string
    country: string
    venue: {
      id: number
      ggPlaceId: string
      name: string
    }
  }[]
  proposal: { hadProposal: boolean; proposalUrl: string; calendlyEventId: string; finalizationDeadline: string }
  venue: {
    imageUrl: string
    totalWeddings: number
    venueName: string
  }
  colorPalette: ColorPalette
  inspirations: Inspiration[]
  stemPreferences: CustomerStemPreference[]
  eventNeeds: {
    id: number
    needKey: string
    needValues: string[]
  }[]
  mostRecentMessage?: CommentItem
  overviewCarousels?: OverviewCarousel[]
  actionItems?: ProposalActionItem[]
  orbConfig?: OrbConfig
  skipServiceability?: boolean
}

interface GuineaPigConfigValue {
  enabled: boolean
  budget: {
    enabled: boolean
    min: number
    max: number
  }
  colorPalette: {
    enabled: boolean
    colorPaletteIds: number[]
  }
  planningStage: {
    enabled: boolean
    planningStages: string[]
  }
}

interface GuineaPigConfig {
  value: GuineaPigConfigValue
}
export interface ISubmitQuizQuestionResponse {
  redirectLink: string
  integratedFireworkExperience: boolean
  premerchandizedKits: boolean
  isActivePremerchandizedKits: boolean
  isActive1000Customer: boolean
  proposalUrl: string
  shouldReportAtiumConversion: boolean
  leadValueConfig: { leadValuesByBudget: { budget: number; value: number }[] }
  leadValue: number
  guineaPigConfig: GuineaPigConfig
  proposalExperiment?: string[]
}

export interface ISubmitQuizQuestionPayload {
  sessionId: string
  firstName?: string
  lastName?: string
  emailAddress?: string
  attributionSource?: string
}
