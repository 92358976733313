/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppContext } from 'src/helpers/app'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from 'src/pages/quiz/contextAPI'
import { budgets, BUDGET_500_INDEX } from '../../Budget/Budget.hook'
import { PATH_URL, REDIRECT_PATH } from 'src/helpers'
import { ColorPalette } from 'src/types'
import { SESSION_NAME_LOCAL } from 'src/helpers/app'
import { ENV } from 'src/config/env'

const hardCodedPalettes: any[] = [
  {
    ids: {
      dev: 5,
      staging: 5,
      production: 5,
    },
    insertedAt: '2022-05-05T09:22:55.961Z',
    updatedAt: '2023-07-06T18:12:50.497Z',
    name: 'Elegant White',
    description: 'Featuring a range of cream florals and greenery.',
    colors: [
      {
        hex: '#3F4D1B',
        width: 45,
      },
      {
        hex: '#fff',
        width: 45,
      },
    ],
    colorList: ['green', 'white'],
    image: 'https://d1ebh4ynks963n.cloudfront.net/Bouquet+Photos/ElegantWhite.jpg',
    sortOrder: 1,
  },
  {
    ids: {
      dev: 10,
      staging: 10,
      production: 10,
    },
    insertedAt: '2022-05-05T09:22:55.961Z',
    updatedAt: '2023-07-06T18:12:50.497Z',
    name: 'Blush',
    description: 'Featuring a range of soft pinks and cream florals.',
    colors: [
      {
        hex: '#EBCECE',
        width: 45,
      },
      {
        hex: '#F2F1EA',
        width: 45,
      },
    ],
    colorList: ['blush', 'white'],
    image: 'https://d1ebh4ynks963n.cloudfront.net/Bouquet+Photos/Blush.jpg',
    sortOrder: 2,
  },
  {
    ids: {
      dev: 3,
      staging: 3,
      production: 3,
    },
    insertedAt: '2022-05-05T09:22:55.961Z',
    updatedAt: '2023-07-06T18:12:50.497Z',
    name: 'Citrus',
    description: 'Featuring bright pinks, oranges, golds, and corals.',
    colors: [
      {
        hex: '#E36795',
        width: 15,
      },
      {
        hex: '#F88379',
        width: 15,
      },
      {
        hex: '#ED9334',
        width: 15,
      },
      {
        hex: '#F1C232',
        width: 15,
      },
      {
        hex: '#EBCECE',
        width: 15,
      },
      {
        hex: '#F2F1EA',
        width: 15,
      },
    ],
    colorList: ['pink', 'coral', 'orange', 'yellow', 'blush', 'white'],
    image: 'https://d1ebh4ynks963n.cloudfront.net/Bouquet+Photos/Citrus.jpg',
    sortOrder: 3,
  },
  {
    ids: {
      dev: 7,
      staging: 7,
      production: 7,
    },
    insertedAt: '2022-05-05T09:22:55.961Z',
    updatedAt: '2023-07-06T18:12:50.497Z',
    name: 'Sunlight',
    description: 'Featuring bright, cheery golds and ranges of cream.',
    colors: [
      {
        hex: '#E0BB41',
        width: 22,
      },
      {
        hex: '#F1C232',
        width: 22,
      },
      {
        hex: '#FCE5CD',
        width: 22,
      },
      {
        hex: '#F2F1EA',
        width: 22,
      },
    ],
    colorList: ['gold', 'yellow', 'cream', 'green'],
    image: 'https://d1ebh4ynks963n.cloudfront.net/Bouquet+Photos/Sunlight.jpg',
    sortOrder: 4,
  },
  {
    ids: {
      dev: 4,
      staging: 4,
      production: 4,
    },
    insertedAt: '2022-05-05T09:22:55.961Z',
    updatedAt: '2023-07-06T18:12:50.497Z',
    name: 'Jewel Tones',
    description: 'Featuring rich purples, reds, blues, and violets.',
    colors: [
      {
        hex: '#3E1E45',
        width: 18,
      },
      {
        hex: '#193380',
        width: 18,
      },
      {
        hex: '#931F1F',
        width: 18,
      },
      {
        hex: '#D9257F',
        width: 18,
      },
    ],
    colorList: ['eggplant', 'darkblue', 'burgundy', 'hotpink'],
    image: 'https://d1ebh4ynks963n.cloudfront.net/Bouquet+Photos/Jewel+Tones.webp',
    sortOrder: 5,
  },
  {
    ids: {
      dev: 1,
      staging: 1,
      production: 1,
    },
    insertedAt: '2022-05-05T09:22:55.961Z',
    updatedAt: '2023-07-06T18:12:50.497Z',
    name: 'Earthtones',
    description: 'Featuring natural golds, pinks, creams and other neutrals.',
    colors: [
      {
        hex: '#cf7f06',
        width: 22,
      },
      {
        hex: '#ffffff',
        width: 22,
      },
      {
        hex: '#f1f0c2',
        width: 22,
      },
    ],
    colorList: ['terracotta', 'cream', 'white'],
    image: 'https://d1ebh4ynks963n.cloudfront.net/Bouquet+Photos/Earthtones.jpg',
    sortOrder: 6,
  },
  {
    ids: {
      dev: 11,
      staging: 11,
      production: 11,
    },
    insertedAt: '2022-05-05T09:22:55.961Z',
    updatedAt: '2023-07-06T18:12:50.497Z',
    name: 'Pastels',
    description: 'Featuring soft pinks, blues, lilacs, and gold florals.',
    colors: [
      {
        hex: '#EFCFCF',
        width: 18,
      },
      {
        hex: '#99BEEF',
        width: 18,
      },
      {
        hex: '#D9D2E9',
        width: 18,
      },
      {
        hex: '#FCE5CD',
        width: 18,
      },
    ],
    colorList: ['blush', 'lightblue', 'lavender', 'cream'],
    image: 'https://d1ebh4ynks963n.cloudfront.net/Bouquet+Photos/Pastels.jpg',
    sortOrder: 7,
  },
  {
    ids: {
      dev: 8,
      staging: 8,
      production: 8,
    },
    insertedAt: '2022-05-05T09:22:55.961Z',
    updatedAt: '2023-07-06T18:12:50.497Z',
    name: 'Golden Canyon',
    description: 'Featuring bold, rich, oranges, golds, reds, and creams.',
    colors: [
      {
        hex: '#78233A',
        width: 18,
      },
      {
        hex: '#ED9334',
        width: 18,
      },
      {
        hex: '#F1C232',
        width: 18,
      },
      {
        hex: '#F2F1EA',
        width: 18,
      },
      {
        hex: '#3F4D1B',
        width: 18,
      },
    ],
    colorList: ['burgundy', 'orange', 'yellow', 'white'],
    image: 'https://d1ebh4ynks963n.cloudfront.net/Bouquet+Photos/GoldenCanyon.jpg',
    sortOrder: 8,
  },
  {
    ids: {
      dev: 2,
      staging: 2,
      production: 2,
    },
    insertedAt: '2022-05-05T09:22:55.961Z',
    updatedAt: '2023-07-06T18:12:50.497Z',
    name: 'Twilight',
    description: 'Featuring deep purples, lilacs, and vibrant violets.',
    colors: [
      {
        hex: '#674EA7',
        width: 22,
      },
      {
        hex: '#D9D2E9',
        width: 22,
      },
      {
        hex: '#EBCECE',
        width: 22,
      },
      {
        hex: '#F2F1EA',
        width: 22,
      },
    ],
    colorList: ['purple', 'lavender', 'white'],
    image: 'https://d1ebh4ynks963n.cloudfront.net/Bouquet+Photos/Twilight+2.jpg',
    sortOrder: 9,
  },
  {
    ids: {
      dev: 9,
      staging: 9,
      production: 9,
    },
    insertedAt: '2022-05-05T09:22:55.961Z',
    updatedAt: '2023-07-06T18:12:50.497Z',
    name: 'Bordeaux',
    description: 'Featuring deep reds, pinks, and cream florals.',
    colors: [
      {
        hex: '#78233A',
        width: 30,
      },
      {
        hex: '#EBCECE',
        width: 30,
      },
      {
        hex: '#F2F1EA',
        width: 30,
      },
    ],
    colorList: ['burgundy', 'blush', 'white'],
    image: 'https://d1ebh4ynks963n.cloudfront.net/Bouquet+Photos/Bordeaux.jpg',
    sortOrder: 10,
  },
  {
    ids: {
      dev: 6,
      staging: 6,
      production: 6,
    },
    insertedAt: '2022-05-05T09:22:55.961Z',
    updatedAt: '2023-07-06T18:12:50.497Z',
    name: 'Evergreen & Eucalyptus',
    description: 'Featuring a range of shades of light and dark green.',
    colors: [
      {
        hex: '#3F4D1B',
        width: 45,
      },
      {
        hex: '#719C69',
        width: 45,
      },
    ],
    colorList: ['green'],
    image: 'https://d1ebh4ynks963n.cloudfront.net/Bouquet+Photos/Greenery.jpg',
    sortOrder: 11,
  },
  {
    ids: {
      dev: 12,
      staging: 12,
      production: 12,
    },
    insertedAt: '2022-05-05T09:22:55.961Z',
    updatedAt: '2023-07-06T18:12:50.497Z',
    name: 'Sky',
    description: 'Featuring dark blues, light blues, and a range of creams.',
    colors: [
      {
        hex: '#6FA8DC',
        width: 30,
      },
      {
        hex: '#FCE5CD',
        width: 30,
      },
      {
        hex: '#F2F1EA',
        width: 30,
      },
    ],
    colorList: ['blue', 'cream', 'white'],
    image: 'https://d1ebh4ynks963n.cloudfront.net/Bouquet+Photos/Sky.jpg',
    sortOrder: 12,
  },
  {
    ids: {
      dev: 15,
      staging: 15,
      production: 15,
    },
    insertedAt: '2024-02-21T02:46:07.839Z',
    updatedAt: '2024-02-21T02:46:07.839Z',
    name: 'Rainbow',
    description: 'Featuring a bright array of all the colors of the rainbow.',
    colors: [],
    colorList: ['pink', 'coral', 'orange', 'yellow', 'darkblue'],
    image: 'https://d1ebh4ynks963n.cloudfront.net/Bouquet+Photos/Rainbow.webp',
    sortOrder: 13,
  },
  {
    ids: {
      dev: 16,
      staging: 16,
      production: 16,
    },
    insertedAt: '2024-02-21T02:46:07.839Z',
    updatedAt: '2024-02-21T02:46:07.839Z',
    name: 'Pink',
    description: 'Featuring a range light, dark, and bright pinks.',
    colors: [],
    colorList: ['pink', 'hotpink', 'white'],
    image: 'https://d1ebh4ynks963n.cloudfront.net/Bouquet+Photos/Pink.jpg',
    sortOrder: 14,
  },
]

export const useColorPaletteComponent = () => {
  const history = useHistory()
  const { data, setQuizScreen, quizQuestionData } = useContext(QuizQuestionContext)
  const [paletteSelected, setPaletteSelected] = useState<ColorPalette | undefined>(data.colorPalette)
  const { appData } = useAppContext()

  const handleNext = async () => {
    const skipServiceability = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}').skipServiceability
    if (appData.isMicroWedding) {
      localStorage.setItem(
        'tempPoppySessionData',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}'),
          colorPalette: paletteSelected,
          sessionId: localStorage.getItem(SESSION_NAME_LOCAL),
          budget: +budgets[BUDGET_500_INDEX].value,
        })
      )
    } else {
      localStorage.setItem(
        'tempPoppySessionData',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}'),
          colorPalette: paletteSelected,
          sessionId: localStorage.getItem(SESSION_NAME_LOCAL),
        })
      )
    }

    if (skipServiceability) {
      history.push({ pathname: PATH_URL.YOUR_DETAILS })
      setQuizScreen(SCREEN_QUIZ_QUESTION.YOUR_DETAILS)
    } else {
      history.push({ pathname: PATH_URL.NEW_AVAILABILITY_PAGE })
      setQuizScreen(SCREEN_QUIZ_QUESTION.NEW_AVAILABILITY_PAGE)
    }
  }

  const handleNextWithValue = async (value: ColorPalette) => {
    const skipServiceability = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}').skipServiceability
    setPaletteSelected(value)
    if (appData.isMicroWedding) {
      localStorage.setItem(
        'tempPoppySessionData',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}'),
          colorPalette: value,
          sessionId: localStorage.getItem(SESSION_NAME_LOCAL),
          budget: +budgets[BUDGET_500_INDEX].value,
        })
      )
    } else {
      localStorage.setItem(
        'tempPoppySessionData',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}'),
          colorPalette: value,
          sessionId: localStorage.getItem(SESSION_NAME_LOCAL),
        })
      )
    }

    if (skipServiceability) {
      history.push({ pathname: PATH_URL.YOUR_DETAILS })
      setQuizScreen(SCREEN_QUIZ_QUESTION.YOUR_DETAILS)
    } else {
      history.push({ pathname: PATH_URL.NEW_AVAILABILITY_PAGE })
      setQuizScreen(SCREEN_QUIZ_QUESTION.NEW_AVAILABILITY_PAGE)
    }
  }

  const handleBack = () => {
    localStorage.setItem('tempPoppySessionData', '{}')
    window.location.href = REDIRECT_PATH.HOME
  }

  const nextDisabled = useMemo(() => !paletteSelected, [paletteSelected])

  useEffect(() => {
    if (!paletteSelected && quizQuestionData?.colorPalettes?.length) {
      const currentTempData = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
      const currentColorPalette = currentTempData?.colorPalette
      if (currentColorPalette) {
        setPaletteSelected(currentColorPalette)
      } else {
        setPaletteSelected(undefined)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizQuestionData?.colorPalettes?.length])

  const palettes = ['dev', 'staging', 'production'].includes(ENV.NODE_ENV)
    ? hardCodedPalettes.map((palette) => {
        return {
          id: palette.ids[ENV.NODE_ENV],
          ...palette,
        }
      })
    : quizQuestionData?.colorPalettes || []

  return {
    hardCodedColorPalettes: palettes,
    colorPalettes: quizQuestionData?.colorPalettes || [],
    nextDisabled,
    handleBack,
    handleNext,
    handleNextWithValue,
    paletteSelected,
    setPaletteSelected,
  }
}
